import { useState } from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";

import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";
import { tenantContent } from "@novalabsxyz/tenants";

import DeviceOffline from "./DeviceOffline";
import Note from "./Note";

const useStyles = makeStyles((theme) =>
  createStyles({
    boxImage: {
      width: "100%",
      height: "auto",

      [theme.breakpoints.up("md")]: {
        width: tenantConfig.connectToTheInternet?.image?.width?.desktop,
      },
    },
    submitBtnContainer: {
      textAlign: "center",
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

function Connect() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [isReadInstructions, setIsReadInstructions] = useState<boolean>(false);
  const handleNextClick = () => setIsReadInstructions(true);

  let imageUrl = tenantConfig.connectBoxImage;
  if (isMobile) {
    imageUrl = tenantConfig.connectBoxMobileImage;
  }

  if (isReadInstructions) {
    return <DeviceOffline />;
  }

  return (
    <>
      <Typography component="h1" variant="h1" color="textPrimary" paragraph>
        {tenantConfig.connectToTheInternet?.title || "Connect to Internet and Power"}
      </Typography>
      <Typography component="p" variant="body1" color="textSecondary" paragraph>
        Plug an ethernet cable from your internet router to the port labeled WAN on the{" "}
        {tenantContent.gateway.name}. Next plug the power cable into the gateway.
      </Typography>
      <Note>
        NOTE: Please ensure the ethernet cable provides an IP address via DHCP and is connecting to
        the internet. Test this by plugging the same cable into your laptop (make sure wifi is off)
        and test if you can Google your favorite meme.
      </Note>
      <img className={classNames(classes.boxImage)} src={imageUrl} alt="connect-box" />
      <div className={classes.submitBtnContainer}>
        <Button
          fullWidth={isMobile}
          variant="contained"
          color={tenantConfig.nextBtnColor}
          onClick={handleNextClick}
        >
          Next
        </Button>
      </div>
    </>
  );
}

export default Connect;
