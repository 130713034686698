import api from "@novalabsxyz/onboarding-client/api/api-instance";

import type { Status } from "./status";

export async function isSerialNumberValid(serialNumber: string): Promise<boolean> {
  const response = await api.get(`/agw/${serialNumber}/validate`);
  return response?.data?.isValid;
}

export async function saveWalletId(serialNumber: string, walletId: string) {
  return await api.put(`/agw/${serialNumber}/wallet`, {
    walletId,
  });
}

export async function getStatus(serialNumber: string): Promise<Status> {
  const response = await api.get(`/agw/${serialNumber}/status`);
  return response?.data;
}

export async function getOnboardingTransaction(serialNumber: string): Promise<string> {
  const response = await api.get(`/agw/${serialNumber}/onboarding`);
  return response?.data;
}
