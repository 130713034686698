import { startCase } from "@novalabsxyz/utils/lodash-plus";

export const pluralize = (amount: number, singular: string, plural = `${singular}s`): string =>
  [-1, 1].includes(amount) ? singular : plural;

export const formatLabelFromObjectPath = (path: string, label?: string): string => {
  if (label) {
    return label;
  }

  // Get last segment, avoiding arrays in the path before.
  const split = path.split("]");

  return startCase(split[split.length - 1]);
};
