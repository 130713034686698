import { isString } from "@novalabsxyz/utils/lodash-plus";
import { yup } from "@novalabsxyz/utils/yup";

declare global {
  interface Window {
    __RUNTIME_CONFIG__?: NodeJS.Dict<string>;
  }
}

interface ValueWithSource {
  value?: string;
  source: string;
}

const parseEnvValue = (key: string, value: unknown, location: string): string | undefined => {
  if (!value || !isString(value)) {
    return undefined;
  }
  if (value.includes(key)) {
    // TODO: Replace with logger when implemented.
    // eslint-disable-next-line no-console
    console.warn(
      `Potential pattern presence detected as the environment variable value at "${location}". This can be a result of the incorrect environment variable usage or injection. Key: "${key}". Value: "${value}"`,
    );
    return undefined;
  }

  return value;
};

const getEnvValueFromProcessEnv = (key: string): ValueWithSource => ({
  source: "process.env",
  value:
    parseEnvValue(key, process.env[key], "process.env") ||
    parseEnvValue(`REACT_APP_${key}`, process.env[`REACT_APP_${key}`], "process.env") ||
    parseEnvValue(`RUNTIME_${key}`, process.env[`RUNTIME_${key}`], "process.env") ||
    parseEnvValue(
      `REACT_APP_RUNTIME_${key}`,
      process.env[`REACT_APP_RUNTIME_${key}`],
      "process.env",
    ),
});

const getEnvValueFromWindow = (key: string): ValueWithSource => ({
  source: "window",
  value:
    typeof window === "undefined"
      ? undefined
      : parseEnvValue(key, window.__RUNTIME_CONFIG__?.[key], "window"),
});

export function getEnvValue<Type, TContext, TOut>(
  key: string,
  validator: yup.AnySchema<Type, TContext, TOut>,
  defaults?: ValueWithSource,
): TOut {
  let { value, source } = getEnvValueFromWindow(key);
  if (!value) {
    ({ value, source } = getEnvValueFromProcessEnv(key));
  }

  if (!value && defaults) {
    source = defaults.source;
    value = defaults.value;
  }

  try {
    const validatedValue = validator.validateSync(value);

    return validatedValue;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      throw new Error(
        `${err.errors
          .map((error) => error.replace(/this/i, `'${key}' environment variable`))
          .join(". ")}. Current value: "${value || "undefined"}". Source: "${source}".`,
      );
    }

    throw err;
  }
}
