import type { TenantConfig } from "./types";

export const FreedomFiConfig: TenantConfig = {
  headerClass: "freedomfi",
  connectBoxImage: `${process.env.PUBLIC_URL}/assets/freedomfi/connect-box.svg`,
  connectBoxMobileImage: `${process.env.PUBLIC_URL}/assets/freedomfi/connect-box-mobile.svg`,
  powerButtonImage: `${process.env.PUBLIC_URL}/assets/freedomfi/power-btn.svg`,
  powerButtonMobileImage: `${process.env.PUBLIC_URL}/assets/freedomfi/power-btn-mobile.svg`,
  successImage: `${process.env.PUBLIC_URL}/assets/freedomfi/success-logo.svg`,
  sadImage: `${process.env.PUBLIC_URL}/assets/freedomfi/sad-logo.svg`,
  qrCodeImage: `${process.env.PUBLIC_URL}/assets/freedomfi/qr-code.svg`,
  scanBtnVariant: "contained",
  nextBtnColor: "primary",
};
