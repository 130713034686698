import type { Theme } from "@material-ui/core";

import { tenantsConfig, TenantName } from "@novalabsxyz/tenants/runtime-config";

import bobcatTheme from "./bobcat";
import freedomFiTheme from "./freedomfi";

// @ts-ignore
export let theme: Theme = null;

if (tenantsConfig.tenant === TenantName.FreedomFi) {
  theme = freedomFiTheme;
} else if (tenantsConfig.tenant === TenantName.Bobcat) {
  theme = bobcatTheme;
} else {
  theme = freedomFiTheme;
}
