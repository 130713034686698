import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createSpacing from "@material-ui/core/styles/createSpacing";

const breakpoints = createBreakpoints({});
const spacing = createSpacing(8);

export default createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        padding: "0.9375rem 2rem 1rem 2rem",
        borderRadius: "4px",
      },
      sizeSmall: {
        padding: "0.6875rem 1.25rem",
        borderRadius: "4px",
      },
      outlinedSecondary: {
        padding: "0.9375rem 2rem 1rem 2rem",
        borderRadius: "4px",
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: "0.875rem",
        padding: "0.9375rem 1.125rem 1rem 1.125rem",
        height: "1.357em",
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: "0.875rem",
        lineHeight: "1.3125",
        transform: "translate(18px, 15px) scale(1)",
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: "1.125rem",
        [breakpoints.down("sm")]: {
          fontSize: "0.8125rem",
        },
        "&$active": {
          fontWeight: "bold",
          [breakpoints.down("sm")]: {
            fontWeight: "500",
          },
        },
        "&$completed": {
          fontWeight: "normal",
        },
      },
      vertical: {
        "&$iconContainer": {
          paddingRight: spacing(2),
        },
      },
    },
    MuiStepIcon: {
      root: {
        width: "1.208em",
        height: "1.208em",
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: spacing(2),
      },
      vertical: {
        marginLeft: spacing(2) - 2,
        paddingTop: spacing(1),
      },
      horizontal: {
        display: "none",
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: spacing(0.5),
        paddingRight: 0,
      },
    },
  },
  typography: {
    h1: {
      fontSize: "1.625rem",
      fontWeight: "bold",
      lineHeight: "140%",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      lineHeight: "140%",
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: "bold",
      lineHeight: "140%",
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: "160%",
    },
    body2: {
      fontSize: "0.8125rem",
      lineHeight: "160%",
    },
    button: {
      fontSize: "1rem",
      textTransform: "none",
      lineHeight: "1.1875",
      fontWeight: "bold",
    },
  },
});
