import logoUrl from "./assets/merryiot/logo.png";
import type { TenantContent } from "./types";

export const tenantContent: TenantContent = {
  slug: "merryiot",
  name: "MerryIoT",
  favicon: "/assets/merryiot/favicon.ico",
  logo: {
    url: logoUrl,
    alt: "MerryIoT logo",
  },
  faq: {
    url: "https://www.merryiot.com/FAQ",
    label: "FAQ",
  },
  contactUs: {
    url: "https://www.merryiot.com/Contact",
    label: "Contact Us",
  },
  gateway: {
    name: "MerryIoT Gateway",
  },
};
