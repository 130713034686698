import type { ReactNode } from "react";

import type { Theme } from "@material-ui/core";
import { SvgIcon, withStyles } from "@material-ui/core";
import { CheckCircle, Warning } from "@material-ui/icons";
import classNames from "classnames";

export const styles = (theme: Theme) => ({
  root: {
    display: "block",
    color: theme.palette.text.disabled,
    "&$active": {
      color: theme.palette.primary.main,
    },
    "&$completed": {
      color: theme.palette.primary.main,
    },
    "&$error": {
      color: theme.palette.error.main,
    },
  },
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  active: {},
  completed: {},
  error: {},
});

export interface StepIconProps {
  /**
   * Whether this step is active.
   */
  active?: boolean;
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: {
    root: string;
    error: string;
    completed: string;
    active: string;
    text: string;
  };
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed?: boolean;
  /**
   * Mark the step as failed.
   */
  error?: boolean;
  /**
   * The icon displayed by the step label.
   */
  icon: ReactNode;
}

function StepIcon(props: StepIconProps) {
  const { completed = false, icon, active = false, error = false, classes, ...other } = props;

  if (typeof icon === "number" || typeof icon === "string") {
    if (error) {
      return <Warning className={classNames(classes.root, classes.error)} {...other} />;
    }
    if (completed) {
      return <CheckCircle className={classNames(classes.root, classes.completed)} {...other} />;
    }
    return (
      <SvgIcon
        className={classNames(classes.root, {
          [classes.active]: active,
        })}
        {...other}
      >
        <circle strokeWidth="1" cx="12" cy="12" r="11" />
        <text className={classes.text} x="12" y="16" textAnchor="middle">
          {icon}
        </text>
      </SvgIcon>
    );
  }

  return icon;
}

// @ts-ignore
export default withStyles(styles, { name: "MuiStepIcon" })(StepIcon);
