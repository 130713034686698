import { useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { ServiceConfig } from "@novalabsxyz/client-config";
import { Helmet } from "@novalabsxyz/components/seo";
import { isSerialNumberValid } from "@novalabsxyz/onboarding-client/api/gateways/api";
import { Pages } from "@novalabsxyz/onboarding-client/constants/pages";
import { useAppDispatch, useAppSelector } from "@novalabsxyz/onboarding-client/state/hooks";
import { getPage, setSerialNumber } from "@novalabsxyz/onboarding-client/state/mainSlice";
import serialNumberStorage from "@novalabsxyz/onboarding-client/utils/serialNumberStorage";
import { tenantContent } from "@novalabsxyz/tenants";

import Connect from "./Connect";
import DeviceAuth from "./DeviceAuth";
import Header from "./Header";
import PowerBtn from "./PowerBtn";
import SerialNumber from "./SerialNumber";
import WalletId from "./WalletId";
import Wizard from "./Wizard";
import { useFetchStatusAndRedirect } from "./hooks/useFetchStatusAndRedirect";

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    progressContainer: {
      textAlign: "center",
      paddingTop: spacing(6),
    },
    content: {
      flexGrow: 1,
      [breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    pagesContainer: {
      overflow: "auto",
      padding: spacing(2),
      [breakpoints.up("md")]: {
        paddingTop: spacing(5),
        paddingRight: spacing(16),
      },
      [breakpoints.down("xs")]: {
        flexGrow: 1,
        flexBasis: 0,
      },
    },
    pagesContentContainer: {
      height: "100%",
      flexWrap: "nowrap",
    },
    versionContainer: {
      paddingTop: spacing(3),

      [breakpoints.down("xs")]: {
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
      },
    },
    wizardContainer: {
      [breakpoints.down("xs")]: {
        flexBasis: 0,
      },
    },
  }),
);

function App() {
  const dispatch = useAppDispatch();

  const page = useAppSelector(getPage);
  const fetchStatusAndRedirect = useFetchStatusAndRedirect();

  useEffect(() => {
    async function runEffect() {
      let serialNumber = serialNumberStorage.get();

      if (serialNumber) {
        let isValid;
        try {
          isValid = await isSerialNumberValid(serialNumber);
        } catch {
          isValid = false;
        } finally {
          if (isValid) {
            dispatch(setSerialNumber(serialNumber));
          } else {
            serialNumber = null;
            serialNumberStorage.remove();
          }
        }
      }

      await fetchStatusAndRedirect(serialNumber);
    }

    void runEffect();
  }, [dispatch, fetchStatusAndRedirect]);

  const classes = useStyles();

  const isLoading = page === Pages.Initial;

  let Content;
  switch (page) {
    case Pages.EnterWalletId:
      Content = WalletId;
      break;

    case Pages.Connect:
      Content = Connect;
      break;

    case Pages.PressPowerBtn:
      Content = PowerBtn;
      break;

    case Pages.DeviceAuth:
      Content = DeviceAuth;
      break;

    case Pages.EnterSerialNumber:
    default:
      Content = SerialNumber;
      break;
  }

  return (
    <Container component="main" disableGutters className={classes.root}>
      <Helmet>
        <title>{tenantContent.name} - Onboarding</title>
        <link rel="icon" href={tenantContent.favicon} />
      </Helmet>
      <Header />
      {isLoading ? (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container className={classes.content}>
          <Grid item xs={12} sm={8} className={classes.pagesContainer}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              className={classes.pagesContentContainer}
            >
              <Grid item>
                <Content />
              </Grid>
              <Grid item className={classes.versionContainer}>
                <Typography variant="body2">Version {ServiceConfig.Version}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.wizardContainer}>
            <Wizard />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default App;
