import { useMemo } from "react";

import { useSnackbar } from "material-ui-snackbar-provider";

type MessageType = "info" | "warning" | "error" | "success";

type ShowMessageParams = {
  type: MessageType;
  message: string;
  action?: string;
  handleAction?: () => void;
  customParameters?: any;
};

export default function useCustomSnackbar() {
  const snackbar = useSnackbar();

  return useMemo(() => {
    const showMessage = ({
      type = "info",
      message,
      action,
      handleAction,
      customParameters,
    }: ShowMessageParams) => {
      snackbar.showMessage(message, action, handleAction, {
        ...customParameters,
        type,
      });
    };

    return {
      ...snackbar,
      showMessage,
      showError: (message: string) => {
        showMessage({
          type: "error",
          message,
        });
      },
      showNetworkError: (error: any) => {
        showMessage({
          type: "error",
          message: error?.response?.data?.message || "Network Error",
        });
      },
    };
  }, [snackbar]);
}
