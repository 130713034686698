import logoUrl from "./assets/linxdot/logo-white.svg";
import type { TenantContent } from "./types";

export const tenantContent: TenantContent = {
  slug: "linxdot",
  name: "Linxdot",
  favicon: "/assets/linxdot/favicon.ico",
  logo: {
    url: logoUrl,
    alt: "Linxdot logo",
  },
  faq: {
    url: "https://www.linxdot.com/support/",
    label: "FAQ",
  },
  contactUs: {
    url: "https://www.linxdot.com/support/",
    label: "Contact Us",
  },
  gateway: {
    name: "Linxdot Gateway",
  },
};
