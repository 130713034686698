import type { ThemeOptions } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import commonTheme from "./common";

const freedomfiTheme: ThemeOptions = {
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          backgroundColor: "#f6f6f6",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: "#4A85F7",
        "&:hover": {
          backgroundColor: "#4A85F7",
        },
        "&$label": {
          color: "#ffffff",
        },
      },
      containedSecondary: {
        color: "#2F2F2F",
        backgroundColor: "#EACE03",
        "&:hover": {
          backgroundColor: "#EACE03",
          "@media (hover: none)": {
            backgroundColor: "#EACE03",
          },
        },
        "&$label": {
          color: "#2F2F2F",
        },
      },
    },
    MuiStepConnector: {
      root: {
        visibility: "hidden",
      },
      lineVertical: {
        borderColor: "#4A85F7",
      },
      active: {
        visibility: "visible",
      },
      completed: {
        visibility: "visible",
      },
    },
    MuiStepIcon: {
      root: {
        stroke: "#76829C",
        color: "transparent",
        "&$active": {
          color: "transparent",
        },
        "&$completed": {
          color: "#4A85F7",
        },
      },
      active: {
        stroke: "#4A85F7",
      },
      completed: {
        stroke: "#4A85F7",
      },
    },
    MuiStepLabel: {
      label: {
        color: "#76829C",
        "&$active": {
          color: "#5E6B86",
        },
        "&$completed": {
          color: "#76829C",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#4C4C4C",
      },
    },
  },
  palette: {
    text: {
      primary: "#2F2F2F",
      secondary: "#565656",
      disabled: "#76829C",
    },
    background: {
      paper: "#ffffff",
      default: "#f6f6f6",
      qrCode: "#F6F6F6",
    },
  },
};

export default createMuiTheme(commonTheme, freedomfiTheme);
