import { useCallback } from "react";

import { Pages } from "@novalabsxyz/onboarding-client/constants/pages";
import { useAppDispatch, useAppSelector } from "@novalabsxyz/onboarding-client/state/hooks";
import { getPage, setPage } from "@novalabsxyz/onboarding-client/state/mainSlice";

import { useFetchStatus } from "./useFetchStatus";

export const useFetchStatusAndRedirect = () => {
  const dispatch = useAppDispatch();
  const fetchStatus = useFetchStatus();
  const currentPage = useAppSelector(getPage);

  const fetchStatusAndRedirect = useCallback(
    async (serialNumber: string | null) => {
      let status;
      let pageToRedirect;

      if (!serialNumber) {
        pageToRedirect = Pages.EnterSerialNumber;
      } else {
        status = await fetchStatus(serialNumber);
      }

      if (status) {
        if (!status.hasWalletId) {
          pageToRedirect = Pages.EnterWalletId;
        } else if (status.isAuthenticated) {
          pageToRedirect = Pages.DeviceAuth;
        } else if (!status.isOnline || !status.isInitialized) {
          pageToRedirect = Pages.Connect;
        } else {
          pageToRedirect = Pages.PressPowerBtn;
        }
      }

      if (pageToRedirect && pageToRedirect !== currentPage) {
        dispatch(setPage(pageToRedirect));
      }

      return status;
    },
    [dispatch, currentPage, fetchStatus],
  );

  return fetchStatusAndRedirect;
};
