import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { Pages } from "@novalabsxyz/onboarding-client/constants/pages";

import type { RootState } from "./store";

interface MainState {
  page: Pages;
  serialNumber: string;
  errorMessage: string;
}

const initialState: MainState = {
  page: Pages.Initial,
  serialNumber: "",
  errorMessage: "",
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Pages>) => {
      state.page = action.payload;
    },
    setSerialNumber: (state, action: PayloadAction<string>) => {
      state.serialNumber = action.payload;
    },
  },
});

export const { setPage, setSerialNumber } = mainSlice.actions;

export const getPage = (state: RootState) => state.main.page;
export const getSerialNumber = (state: RootState) => state.main.serialNumber;

export default mainSlice.reducer;
