// Yup schema validation tool.
// More at {@link https://docs.yup.io/ Yup docs}.

// eslint-disable-next-line import/no-namespace
import * as yup from "yup";

import { formatLabelFromObjectPath } from "@novalabsxyz/utils/text";

// Set custom error messages format.
yup.setLocale({
  array: {
    min: ({ path, label, min }) =>
      `${formatLabelFromObjectPath(path, label)} can not have less than ${min} items`,
    max: ({ path, label, max }) =>
      `${formatLabelFromObjectPath(path, label)} can not have mote than ${max} items`,
  },
  mixed: {
    required: ({ path, label }) => `${formatLabelFromObjectPath(path, label)} is required`,
    // TODO: No list of values.
    oneOf: ({ path, label }) =>
      `${formatLabelFromObjectPath(path, label)} must be one of the provided values.`,
  },
  number: {
    min: ({ path, label, min }) =>
      `${formatLabelFromObjectPath(path, label)} can not be less than ${min}`,
    max: ({ path, label, max }) =>
      `${formatLabelFromObjectPath(path, label)} can not be more than ${max}`,
    lessThan: ({ path, label, less }) =>
      `${formatLabelFromObjectPath(path, label)} must be less than ${less}`,
    moreThan: ({ path, label, more }) =>
      `${formatLabelFromObjectPath(path, label)} must be more than ${more}`,
    positive: ({ path, label }) => `${formatLabelFromObjectPath(path, label)} must be positive`,
    integer: ({ path, label }) => `${formatLabelFromObjectPath(path, label)} must be an integer`,
  },
  string: {
    email: "Incorrect email format",
    matches: ({ path, label }) => `Incorrect ${formatLabelFromObjectPath(path, label)} format`,
    min: ({ path, label, min }) =>
      `${formatLabelFromObjectPath(path, label)} must contain a minimum of ${min} characters`,
    max: ({ path, label, max }) =>
      `${formatLabelFromObjectPath(path, label)} must contain a minimum of ${max} characters`,
  },
});

export { yup };
