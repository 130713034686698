import { EnvironmentType } from "@novalabsxyz/constants/env";
import { getEnvValue } from "@novalabsxyz/utils/env";
import { yup } from "@novalabsxyz/utils/yup";

export const ServiceConfig = {
  Environment: getEnvValue(
    "NODE_ENV",
    yup.string().required().oneOf(Object.values(EnvironmentType)),
  ) as EnvironmentType,
  Version: getEnvValue("VERSION", yup.string().required()),
};
