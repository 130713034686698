import logoUrl from "./assets/bobcat/logo.svg?url";
import type { TenantContent } from "./types";

export const tenantContent: TenantContent = {
  slug: "bobcat",
  name: "Bobcat",
  favicon: "/assets/bobcat/favicon.ico",
  logo: {
    url: logoUrl,
    alt: "Bobcat logo",
  },
  faq: {
    url: "http://bobcatminer.zendesk.com",
    label: "Help desk",
  },
  contactUs: {
    url: "http://bobcatminer.zendesk.com",
    label: "Contact Us",
  },
  gateway: {
    name: "Bobber 500 Gateway",
  },
};
