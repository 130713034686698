import { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getOnboardingTransaction } from "@novalabsxyz/onboarding-client/api/gateways/api";
import { useAppSelector, useAppDispatch } from "@novalabsxyz/onboarding-client/state/hooks";
import { getSerialNumber } from "@novalabsxyz/onboarding-client/state/mainSlice";
import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";
import { getEnvValue } from "@novalabsxyz/utils/env";
import { yup } from "@novalabsxyz/utils/yup";

import useCustomSnackbar from "./hooks/useCustomSnackbar";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
      paddingTop: theme.spacing(6),
    },
    image: {
      marginBottom: theme.spacing(6),
      width: "40%",
      height: "auto",
      [theme.breakpoints.up("sm")]: {
        width: "20%",
      },
    },
    title: {
      marginBottom: theme.spacing(4),
    },
  }),
);

function DeviceAuth() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const snackbar = useCustomSnackbar();

  const dispatch = useAppDispatch();
  const serialNumber = useAppSelector(getSerialNumber);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [transaction, setTransaction] = useState<string>("");

  useEffect(() => {
    if (!serialNumber) {
      return;
    }

    const fetch = async () => {
      let newTransaction;
      let hasNetworkError = false;

      try {
        newTransaction = await getOnboardingTransaction(serialNumber);
      } catch (error) {
        snackbar.showNetworkError(error);
        hasNetworkError = true;
      } finally {
        if (newTransaction) {
          setTransaction(newTransaction);
        } else if (!hasNetworkError) {
          snackbar.showError("Error. Please refresh the page");
        }

        setIsLoading(false);
      }
    };

    void fetch();
  }, [snackbar, dispatch, serialNumber]);

  if (isLoading || !transaction) {
    return null;
  }

  const link = getEnvValue("ADD_GATEWAY_LINK", yup.string().required()).replace(
    /TRANSACTION/,
    transaction,
  );

  return (
    <div className={classes.root}>
      <img className={classes.image} src={tenantConfig.successImage} alt="power-btn" />
      <Typography
        className={classes.title}
        component="h1"
        variant="h1"
        color="textPrimary"
        paragraph
      >
        Authentication succeeded
      </Typography>
      <Typography component="p" variant="body1" color="textSecondary" paragraph>
        {tenantConfig.deviceAuth?.note ||
          "Nice! You did it! Click the link below and it will take you back to the Helium App to finish the onboarding process."}
      </Typography>
      <Button
        fullWidth={isMobile}
        href={link}
        disabled={!link}
        variant="contained"
        color={tenantConfig.nextBtnColor}
      >
        Link Gateway
      </Button>
    </div>
  );
}

export default DeviceAuth;
