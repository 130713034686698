import type { ThemeOptions } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import commonTheme from "./common";

const bobcatTheme: ThemeOptions = {
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          backgroundColor: "#212121",
        },
      },
    },
    MuiButton: {
      label: {
        color: "#FFFFFF",
      },
      containedSecondary: {
        backgroundColor: "#EE223B",
        "&:hover": {
          backgroundColor: "#df0924",
        },
        "&$disabled": {
          backgroundColor: "rgba(255, 255, 255, 0.12)",
        },
      },
      outlinedSecondary: {
        borderColor: "#EE223B",
        "&:hover": {
          backgroundColor: "rgb(223, 9, 36, 0.04)",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "#d6d6d6",
        borderColor: "#464646",
        background: "#464646",
        "&:disabled label": {
          color: "#d6d6d6",
        },
      },
      input: {
        color: "#d6d6d6",
      },
    },
    MuiFormLabel: {
      root: {
        "&$disabled": {
          color: "#d6d6d6",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#d6d6d6",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#FFFFFF",
      },
    },
    MuiStepLabel: {
      label: {
        color: "#d6d6d6",
        "&$completed": {
          color: "#d6d6d6",
        },
      },
      active: {
        color: "#ffffff",
      },
      disabled: {
        color: "#d6d6d6",
      },
    },
    MuiStepConnector: {
      root: {
        visibility: "hidden",
      },
      lineVertical: {
        borderColor: "#EE223B",
      },
      active: {
        visibility: "visible",
      },
      completed: {
        visibility: "visible",
      },
    },
    MuiStepIcon: {
      root: {
        stroke: "#d6d6d6",
        color: "#212121",
        "&$active": {
          color: "#212121",
        },
        "&$completed": {
          color: "#EE223B",
          stroke: "#EE223B",
          clipPath: "circle(12px at center)",
        },
      },
      active: {
        "& circle": {
          stroke: "#EE223B",
        },
        "&$text": {
          stroke: "#ffffff",
        },
      },
      completed: {
        "& circle": {
          stroke: "#EE223B",
        },
      },
    },
  },
  palette: {
    text: {
      primary: "#ffffff",
      secondary: "#d6d6d6",
      disabled: "#d6d6d6",
    },
    background: {
      paper: "#464646",
      default: "#212121",
      qrCode: "#333333",
    },
  },
};

export default createMuiTheme(commonTheme, bobcatTheme);
