import { getEnvValue } from "@novalabsxyz/utils/env";
import { yup } from "@novalabsxyz/utils/yup";

import { tenantContent as bobcatTenantContent } from "./content/index.bobcat";
import { tenantContent as freedomFiTenantContent } from "./content/index.freedomfi";
import { tenantContent as linxdotTenantContent } from "./content/index.linxdot";
import { tenantContent as merryIoTTenantContent } from "./content/index.merryiot";
import { tenantContent as nebraTenantContent } from "./content/index.nebra";

// eslint-disable-next-line no-restricted-imports
import { updateTenantContent } from ".";

export enum TenantName {
  FreedomFi = "freedomfi",
  Bobcat = "bobcat",
  Linxdot = "linxdot",
  MerryIoT = "merryiot",
  Nebra = "nebra",
}

const tenantsContentMap = {
  [TenantName.Bobcat]: bobcatTenantContent,
  [TenantName.FreedomFi]: freedomFiTenantContent,
  [TenantName.Linxdot]: linxdotTenantContent,
  [TenantName.MerryIoT]: merryIoTTenantContent,
  [TenantName.Nebra]: nebraTenantContent,
};

export const tenantsConfig = {
  tenant: getEnvValue(
    "PUBLIC_TENANT",
    yup.string().required().oneOf(Object.values(TenantName)),
  ) as TenantName,
};

updateTenantContent(tenantsContentMap[tenantsConfig.tenant]);
