import axios from "axios";

import { getEnvValue } from "@novalabsxyz/utils/env";
import { yup } from "@novalabsxyz/utils/yup";

const api = axios.create({
  baseURL: getEnvValue("API_URL", yup.string().required()),
});

export default api;
