import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";

import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";
import { tenantContent } from "@novalabsxyz/tenants";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      "&.bobcat": {
        minHeight: theme.spacing(9),
      },
    },
    logo: {
      height: "1.9em",
      "&.bobcat": {
        height: "2.6em",
      },
    },
  }),
);

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();

  return (
    <AppBar position="static" color="primary">
      <Toolbar className={classNames(classes.toolbar, tenantConfig.headerClass)}>
        <img
          className={classNames(classes.logo, tenantConfig.headerClass)}
          src={tenantContent.logo.url}
          alt={tenantContent.logo.alt}
        />
        {isMobile && (
          <Button
            href={tenantContent.contactUs.url}
            rel="nofollow noopener noreferrer"
            target="_blank"
            size="small"
            color="secondary"
            variant="contained"
          >
            {tenantContent.contactUs.label}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
