import { useState } from "react";

import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import type { Status } from "@novalabsxyz/onboarding-client/api/gateways/status";
import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";

import { usePollStatusAndRedirect } from "./hooks/usePollStatusAndRedirect";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
      paddingTop: theme.spacing(6),
    },
    image: {
      marginBottom: theme.spacing(6),
      width: "40%",
      height: "auto",
      [theme.breakpoints.up("sm")]: {
        width: "20%",
      },
    },
    message: {
      animation: "blinkingEffect 1s linear infinite",
    },
  }),
);

function DeviceOffline() {
  const classes = useStyles();
  const [status, setStatus] = useState<Status | undefined>();

  usePollStatusAndRedirect(setStatus);

  let message = "Waiting for your device to come online";
  if (status?.isOnline) {
    if (!status.isInitialized) {
      message = "Waiting for gateway to send initialization data";
    }
  }

  return (
    <div className={classes.root}>
      <img className={classes.image} src={tenantConfig.sadImage} alt="power-btn" />
      <Typography
        className={classes.message}
        component="h2"
        variant="h2"
        color="textPrimary"
        paragraph
      >
        {message}
      </Typography>
      <Typography component="p" variant="body1" color="textSecondary" paragraph>
        (this may take up to 15 minutes)
      </Typography>
    </div>
  );
}

export default DeviceOffline;
