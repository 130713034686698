import type { ButtonProps } from "@material-ui/core/Button";
import Button from "@material-ui/core/Button";
import type { SnackbarProps } from "@material-ui/core/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

type CustomSnackbarProps = {
  message?: string;
  action?: string;
  ButtonProps?: Partial<ButtonProps>;
  SnackbarProps: Partial<SnackbarProps>;
  customParameters: any;
};

function CustomSnackbar({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}: CustomSnackbarProps) {
  return (
    <Snackbar {...SnackbarProps}>
      <Alert
        variant="filled"
        severity={customParameters?.type}
        action={
          action != null && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
