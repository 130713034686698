import type { TenantConfig } from "./types";

export const BobcatConfig: TenantConfig = {
  headerClass: "bobcat",
  connectBoxImage: `${process.env.PUBLIC_URL}/assets/bobcat/connect-box.svg`,
  connectBoxMobileImage: `${process.env.PUBLIC_URL}/assets/bobcat/connect-box.svg`,
  powerButtonImage: `${process.env.PUBLIC_URL}/assets/bobcat/power-btn.svg`,
  powerButtonMobileImage: `${process.env.PUBLIC_URL}/assets/bobcat/power-btn.svg`,
  successImage: `${process.env.PUBLIC_URL}/assets/bobcat/success-logo.svg`,
  sadImage: `${process.env.PUBLIC_URL}/assets/bobcat/sad-logo.svg`,
  qrCodeImage: `${process.env.PUBLIC_URL}/assets/bobcat/qr-code.svg`,
  scanBtnVariant: "outlined",
  nextBtnColor: "secondary",

  connectToTheInternet: {
    title: "Connect to Internet and Power the Gateway",
    image: {
      width: {
        desktop: "70%",
      },
    },
  },
  pairing: {
    description: "Quickly press and release the pairing button once and then click next.",
    note: "NOTE: After you push the pairing button, nothing will beep or change on this screen.",
    image: {
      width: {
        desktop: "70%",
      },
    },
  },
  deviceAuth: {
    note: "Nice! You did it! Click the link below to go back to the Bobber App to finish onboarding.",
  },
};
