import logoUrl from "./assets/freedomfi/logo.svg?url";
import type { TenantContent } from "./types";

export const tenantContent: TenantContent = {
  slug: "freedomfi",
  name: "FreedomFi",
  favicon: "/assets/freedomfi/favicon.ico",
  logo: {
    url: logoUrl,
    alt: "FreedomFi logo",
  },
  faq: {
    url: "https://freedomfi.com/faq",
    label: "FAQ",
  },
  contactUs: {
    url: "https://freedomfi.atlassian.net/servicedesk/customer/portal/1",
    label: "Contact Us",
  },
  gateway: {
    name: "FreedomFi Gateway",
  },
};
