import { Fragment, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFormik } from "formik";
import QrReader from "react-qr-reader";
import { object, string } from "yup";

import { isSerialNumberValid } from "@novalabsxyz/onboarding-client/api/gateways/api";
import { useAppDispatch } from "@novalabsxyz/onboarding-client/state/hooks";
import { setSerialNumber } from "@novalabsxyz/onboarding-client/state/mainSlice";
import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";
import serialNumberStorage from "@novalabsxyz/onboarding-client/utils/serialNumberStorage";

import useCustomSnackbar from "./hooks/useCustomSnackbar";
import { useFetchStatusAndRedirect } from "./hooks/useFetchStatusAndRedirect";

const useStyles = makeStyles((theme) =>
  createStyles({
    qrReaderContainer: {
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    qrReader: {
      width: "50%",
      margin: `0 auto ${theme.spacing(2)}px`,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    qrInfoContainer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0, 4, 0),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(6),
        display: "flex",
      },
    },
    qrIconContainer: {
      backgroundColor: theme.palette.background.qrCode,
      padding: theme.spacing(2),
    },
    qrIcon: {
      height: "auto",
      width: 218,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    qrInfo: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2),
      },
    },
    qrScanActions: {
      display: "flex",
      flexWrap: "wrap",
    },
    qrScanBtn: {
      minWidth: 180,
      width: "100%",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2),
        width: 180,
      },
    },
    qrScanFileInput: {
      display: "none",
    },
    qrScanUploadBtn: {
      minWidth: 180,
      width: "100%",
      display: "inline-block",
      [theme.breakpoints.up("sm")]: {
        width: 180,
      },
    },

    form: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    inputContainer: {
      flexGrow: 1,
      margin: theme.spacing(0, 0, 2, 0),
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2),
      },
    },
    submitBtnContainer: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  }),
);

function SerialNumber() {
  const serialNumberInputRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const snackbar = useCustomSnackbar();

  const dispatch = useAppDispatch();
  const fetchStatusAndRedirect = useFetchStatusAndRedirect();

  const formik = useFormik({
    initialValues: {
      serialNumber: "",
    },
    validationSchema: object().shape({
      serialNumber: string().required("Serial Number is required"),
    }),
    onSubmit: async ({ serialNumber }) => {
      let isValid = false;

      try {
        isValid = await isSerialNumberValid(serialNumber);
      } catch (error) {
        snackbar.showNetworkError(error);
        return;
      }

      if (!isValid) {
        snackbar.showError("Invalid serial number");
        return;
      }

      serialNumberStorage.set(serialNumber);
      dispatch(setSerialNumber(serialNumber));
      await fetchStatusAndRedirect(serialNumber);
    },
  });

  const classes = useStyles();

  const [isScanning, setIsScanning] = useState<boolean>(false);

  const startScanning = () => {
    setIsScanning(true);
  };

  const stopScanning = () => {
    setIsScanning(false);
  };

  const handleScan = (data: any) => {
    if (data) {
      void formik.setFieldValue("serialNumber", data);
      setIsScanning(false);

      if (serialNumberInputRef?.current) {
        serialNumberInputRef.current.scrollIntoView();
      }
    }
  };

  const handleError = () => {
    snackbar.showError("Scanning Error. Please try again");
  };

  return (
    <Fragment>
      {!isScanning && (
        <Typography component="h1" variant="h1" color="textPrimary" paragraph>
          Enter Serial Number
        </Typography>
      )}
      {!isScanning && (
        <Typography component="p" variant="body1" color="textSecondary" paragraph>
          To start onboarding your hotspot, we need to get your serial number. Flip the gateway over
          and point your phone camera at the QR code on the bottom of the unit.
        </Typography>
      )}
      {isScanning && (
        <div className={classes.qrReaderContainer}>
          <div>
            <QrReader className={classes.qrReader} onError={handleError} onScan={handleScan} />
          </div>
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="secondary"
            disabled={formik.isSubmitting}
            onClick={stopScanning}
          >
            Stop scanning
          </Button>
        </div>
      )}
      {!isScanning && (
        <div className={classes.qrInfoContainer}>
          <div className={classes.qrIconContainer}>
            <img className={classes.qrIcon} src={tenantConfig.qrCodeImage} alt="qr-code" />
          </div>
          <div className={classes.qrInfo}>
            <Typography component="h3" variant="h3" color="textPrimary" paragraph>
              Use Scan
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" paragraph>
              Press &quot;Scan&quot; button, then aim your camera at the QR code.
            </Typography>
            <div className={classes.qrScanActions}>
              <Button
                className={classes.qrScanBtn}
                variant={tenantConfig.scanBtnVariant}
                color="secondary"
                disabled={formik.isSubmitting}
                onClick={startScanning}
              >
                Scan
              </Button>
            </div>
          </div>
        </div>
      )}
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className={classes.inputContainer}>
          <TextField
            disabled
            ref={serialNumberInputRef}
            fullWidth
            variant="outlined"
            id="serialNumber"
            name="serialNumber"
            label="Serial Number"
            value={formik.values.serialNumber}
            onChange={formik.handleChange}
            error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
            helperText={formik.touched.serialNumber && formik.errors.serialNumber}
          />
        </div>
        <div className={classes.submitBtnContainer}>
          <Button
            fullWidth={isMobile}
            type="submit"
            variant="contained"
            color={tenantConfig.nextBtnColor}
            disabled={formik.isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

export default SerialNumber;
