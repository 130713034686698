import logoUrl from "./assets/nebra/logo-white.svg";
import type { TenantContent } from "./types";

export const tenantContent: TenantContent = {
  slug: "nebra",
  name: "Nebra",
  favicon: "/assets/nebra/favicon.ico",
  logo: {
    url: logoUrl,
    alt: "Nebra logo",
  },
  faq: {
    url: "https://support.nebra.com",
    label: "FAQ",
  },
  contactUs: {
    url: "https://support.nebra.com",
    label: "Contact Us",
  },
  gateway: {
    name: "Nebra Gateway",
  },
};
