import { useCallback } from "react";

import { getStatus } from "@novalabsxyz/onboarding-client/api/gateways/api";

import useCustomSnackbar from "./useCustomSnackbar";

export const useFetchStatus = () => {
  const snackbar = useCustomSnackbar();

  const fetchStatus = useCallback(
    async (serialNumber: string) => {
      try {
        return await getStatus(serialNumber);
      } catch (error) {
        snackbar.showNetworkError(error);

        return undefined;
      }
    },
    [snackbar],
  );

  return fetchStatus;
};
