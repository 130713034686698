import { tenantContent as defaultTenantContent } from "./content";
import type { TenantContent } from "./content/types";

export type { TenantContent } from "./content/types";

// TODO: Update to class.
// eslint-disable-next-line import/no-mutable-exports
let tenantContent = defaultTenantContent;

/**
 * Function allows to manually update exported tenant content,
 * in a case when ap bundler configuration does not support import by file name suffix.
 */
export const updateTenantContent = (newTenantContent: TenantContent): void => {
  tenantContent = newTenantContent;
};

export { tenantContent };
