import { tenantsConfig, TenantName } from "@novalabsxyz/tenants/runtime-config";

import { BobcatConfig } from "./bobcat";
import { FreedomFiConfig } from "./freedomfi";
import type { TenantConfig } from "./types";

// @ts-ignore
export let tenantConfig: TenantConfig = null;

if (tenantsConfig.tenant === TenantName.FreedomFi) {
  tenantConfig = FreedomFiConfig;
} else if (tenantsConfig.tenant === TenantName.Bobcat) {
  tenantConfig = BobcatConfig;
} else {
  tenantConfig = FreedomFiConfig;
}
