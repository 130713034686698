import { useEffect } from "react";

import type { Status } from "@novalabsxyz/onboarding-client/api/gateways/status";
import { useAppSelector } from "@novalabsxyz/onboarding-client/state/hooks";
import { getSerialNumber } from "@novalabsxyz/onboarding-client/state/mainSlice";

import { useFetchStatusAndRedirect } from "./useFetchStatusAndRedirect";

export const usePollStatusAndRedirect = (
  onStatusUpdated?: (status: Status | undefined) => void,
): void => {
  const serialNumber = useAppSelector(getSerialNumber);
  const fetchStatusAndRedirect = useFetchStatusAndRedirect();

  useEffect(() => {
    if (!serialNumber) {
      return;
    }

    let isMounted = true;

    const fetch = async (): Promise<void> => {
      if (!isMounted) {
        return;
      }

      const status = await fetchStatusAndRedirect(serialNumber);

      if (onStatusUpdated) {
        onStatusUpdated(status);
      }

      if (
        status &&
        status.isInitialized &&
        status.isOnline &&
        status.hasWalletId &&
        status.isAuthenticated
      ) {
        return;
      }

      setTimeout(fetch, 3000);
    };

    void fetch();

    return () => {
      isMounted = false;
    };
  }, [onStatusUpdated, serialNumber, fetchStatusAndRedirect]);
};
