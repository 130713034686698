import { Fragment } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFormik } from "formik";
import { object, string } from "yup";

import { saveWalletId } from "@novalabsxyz/onboarding-client/api/gateways/api";
import { useAppSelector } from "@novalabsxyz/onboarding-client/state/hooks";
import { getSerialNumber } from "@novalabsxyz/onboarding-client/state/mainSlice";
import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";

import useCustomSnackbar from "./hooks/useCustomSnackbar";
import { useFetchStatusAndRedirect } from "./hooks/useFetchStatusAndRedirect";
import { useQuery } from "./hooks/useQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(6),
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    inputContainer: {
      flexGrow: 1,
      margin: theme.spacing(0, 0, 2, 0),
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2),
      },
    },
    submitBtnContainer: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  }),
);

function WalletId() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const query = useQuery();

  const snackbar = useCustomSnackbar();

  const serialNumber = useAppSelector(getSerialNumber);
  const fetchStatusAndRedirect = useFetchStatusAndRedirect();

  const formik = useFormik({
    initialValues: {
      walletId: query.get("wallet_id") || "",
    },
    validationSchema: object().shape({
      walletId: string().required("Wallet ID is required"),
    }),
    onSubmit: async ({ walletId }) => {
      try {
        await saveWalletId(serialNumber, walletId);
      } catch (error) {
        snackbar.showNetworkError(error);
        return;
      }

      await fetchStatusAndRedirect(serialNumber);
    },
  });

  const classes = useStyles();

  return (
    <Fragment>
      <Typography component="h1" variant="h1" color="textPrimary" paragraph>
        Confirm Wallet ID
      </Typography>
      <Typography component="p" variant="body1" color="textSecondary" paragraph>
        Next, we need your Helium Wallet ID so we know who to send rewards to!
      </Typography>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className={classes.inputContainer}>
          <TextField
            disabled
            variant="outlined"
            fullWidth
            id="walletId"
            name="walletId"
            label="Your Wallet ID"
            value={formik.values.walletId}
            onChange={formik.handleChange}
            error={formik.touched.walletId && Boolean(formik.errors.walletId)}
            helperText={formik.touched.walletId && formik.errors.walletId}
          />
        </div>
        <div className={classes.submitBtnContainer}>
          <Button
            fullWidth={isMobile}
            type="submit"
            variant="contained"
            color={tenantConfig.nextBtnColor}
            disabled={formik.isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

export default WalletId;
