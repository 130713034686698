import { Fragment, useState } from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";

import { tenantConfig } from "@novalabsxyz/onboarding-client/tenants";

import Note from "./Note";
import WaitingDeviceAuth from "./WaitingDeviceAuth";

const useStyles = makeStyles((theme) =>
  createStyles({
    powerBtnImg: {
      width: "100%",
      height: "auto",
      [theme.breakpoints.up("sm")]: {
        width: "80%",
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.up("md")]: {
        width: tenantConfig.pairing?.image?.width?.desktop,
      },
    },
    submitBtnContainer: {
      textAlign: "center",
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

function PowerBtn() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [isReadInstructions, setIsReadInstructions] = useState<boolean>(false);
  const handleNextClick = () => setIsReadInstructions(true);

  let imageUrl = tenantConfig.powerButtonImage;
  if (isMobile) {
    imageUrl = tenantConfig.powerButtonMobileImage;
  }

  if (isReadInstructions) {
    return <WaitingDeviceAuth />;
  }

  return (
    <Fragment>
      <Typography component="h1" variant="h1" color="textPrimary" paragraph>
        Press the pairing button
      </Typography>
      <Typography component="p" variant="body1" color="textSecondary" paragraph>
        {tenantConfig.pairing?.description ||
          "Press and release the pairing button once and then click next."}
      </Typography>
      <Note>
        {tenantConfig.pairing?.note ||
          "NOTE: After you push the pairing button, nothing will beep or change on this screen. Just push it once and click Next to proceed to the next screen."}
      </Note>
      <img className={classNames(classes.powerBtnImg)} src={imageUrl} alt="power-btn" />
      <div className={classes.submitBtnContainer}>
        <Button
          fullWidth={isMobile}
          variant="contained"
          color={tenantConfig.nextBtnColor}
          onClick={handleNextClick}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
}

export default PowerBtn;
