import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Pages } from "@novalabsxyz/onboarding-client/constants/pages";
import { useAppSelector } from "@novalabsxyz/onboarding-client/state/hooks";
import { getPage } from "@novalabsxyz/onboarding-client/state/mainSlice";
import { tenantContent } from "@novalabsxyz/tenants";

import StepIcon from "./StepIcon";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("xs")]: {
        height: "100%",
      },
    },
    stepperContainer: {
      flexGrow: 1,
      "& .MuiStepper-root": {
        padding: theme.spacing(2, 1, 2, 2),
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(16, 3, 5, 5),
        },
      },
    },
    contuctUsBtnContainer: {
      textAlign: "center",
      padding: theme.spacing(0, 2, 2, 2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6, 3, 3, 3),
      },
    },
    havingTroubleText: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.disabled,
    },
    activeStep: {
      flex: "1 1 auto",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  }),
);

interface WizardStep {
  page: Pages;
  label: string;
}

const wizardSteps: WizardStep[] = [
  {
    page: Pages.EnterSerialNumber,
    label: "Enter Serial Number",
  },
  {
    page: Pages.EnterWalletId,
    label: "Enter Wallet ID",
  },
  {
    page: Pages.Connect,
    label: "Connect",
  },
  {
    page: Pages.PressPowerBtn,
    label: "Press Pairing Button",
  },
  {
    page: Pages.DeviceAuth,
    label: "Device Authentication",
  },
];

function Wizard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const page = useAppSelector(getPage);

  const classes = useStyles();

  const orientation = isMobile ? "horizontal" : "vertical";

  return (
    <div className={classes.root}>
      <div className={classes.stepperContainer}>
        <Stepper orientation={orientation}>
          {wizardSteps.map((step, index) => {
            const isActive = step.page === page;
            const label = !isMobile || isActive ? step.label : "";
            const isCompleted =
              wizardSteps.findIndex((s) => s.page === step.page) <
              wizardSteps.findIndex((s) => s.page === page);

            return (
              <Step
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={isActive ? classes.activeStep : ""}
                active={isActive}
                completed={isCompleted}
              >
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {!isMobile && (
        <div className={classes.contuctUsBtnContainer}>
          <Button
            href={tenantContent.contactUs.url}
            target="_blank"
            color="secondary"
            variant="contained"
          >
            {tenantContent.contactUs.label}
          </Button>
          <Typography className={classes.havingTroubleText} variant="body2" color="textSecondary">
            Having trouble?
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Wizard;
