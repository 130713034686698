import type { PropsWithChildren } from "react";

import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() =>
  createStyles({
    note: {
      fontStyle: "italic",
      fontWeight: "bold",
      fontSize: "0.75rem",
      lineHeight: "160%",
    },
  }),
);

function Note(props: PropsWithChildren<unknown>) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.note}
      component="p"
      variant="body1"
      color="textSecondary"
      paragraph
    >
      {props.children}
    </Typography>
  );
}

export default Note;
