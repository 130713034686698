import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { usePollStatusAndRedirect } from "./hooks/usePollStatusAndRedirect";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
      paddingTop: theme.spacing(6),
    },
    message: {
      animation: "blinkingEffect 1s linear infinite",
    },
  }),
);

function WaitingDeviceAuth() {
  const classes = useStyles();

  usePollStatusAndRedirect();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.message}
        component="h5"
        variant="h5"
        color="textPrimary"
        paragraph
      >
        Waiting for your device authentication
      </Typography>
      <Typography component="h6" variant="body1" color="textSecondary" paragraph>
        (this may take up to 30 minutes)
      </Typography>
    </div>
  );
}

export default WaitingDeviceAuth;
