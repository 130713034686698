// eslint-disable-next-line import/no-unassigned-import
import "reflect-metadata";
// eslint-disable-next-line import/no-unassigned-import
import "@novalabsxyz/tenants/runtime-config";

import { StrictMode } from "react";

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import App from "./components/App";
import CustomSnackbar from "./components/CustomSnackbar";
import store from "./state/store";
import { theme } from "./themes";

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider SnackbarComponent={CustomSnackbar}>
        <Provider store={store}>
          <Router>
            <CssBaseline />
            <App />
          </Router>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById("root"),
);
